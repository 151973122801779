// import BottomBanner from "@/theme/components/BottomBanner";
import MainSlider, { SwiperSlide } from '@/theme/components/MainSlider'
import { Image } from '@/theme/components/Image'
import { useAppContext } from '@/core/App/AppContext'
import React, { useEffect, useState } from 'react'
import Button from '@/theme/components/Button'
import Link from '@/core/Link'
import { TitleTile } from '@/theme/components/TitleTile'
import { useRouterContext } from '@/core/Router/RouterContext'
import ModalComponent from '@/theme/components/ModalComponent'
import useAuthContext from '@/theme/components/Contexts/AuthContext'
import { setReferralCode } from '@/utils/referral'
import './home-page.scss'
import ProductListAsync from '@/theme/components/ProductListAsync'
import { HtmlHead } from '@/theme/components/HtmlHead'
import { graphqlWebClient } from '@/theme/lib/graphqlClient'
import HomePageBlogAsync from '@/theme/components/HomePageBlogAsync'
import HomePageReviewsAsync from '@/theme/components/HomePageReviewsAsync'
import HomePageTvComponentAsync from '@/theme/components/HomePageTvComponentAsync'
import { sendPageView } from 'src/services/gtm'
import { SpinTheWheelModal } from '@/theme/components/SpinTheWheel/SpinTheWheelModal'
import PersistanceStorage from '@/utils/storage'
import we from 'src/services/webengage'
import LoadMore from '@/theme/components/Load More/LoadMore'
import RenderOnView from '@/theme/components/RenderOnView'
import SpinningWheelOffer from './SpinningWheelOffer'
import useSpinwheelSession from 'src/hooks/useSpinwheelSession'
import GQLQueryAsync from '@/theme/components/GQLQueryAsync'
import { getSpinwheelCouponsQuery } from 'src/graphql/queries/getCoupon'
const storage = new PersistanceStorage()
const midSectionBanners = [
  {
    is_mobile: false,
    link: '',
    title: '',
    banner_category: '',
    offer: '',
    resource_path:
      'https://media.vedistry.com/media/codilar/tmp/banner/images/999-offer-banner-desktop.jpg',
    alt_text: '',
  },
  {
    is_mobile: true,
    link: '',
    title: '',
    banner_category: '',
    offer: '',
    resource_path:
      'https://media.vedistry.com/media/codilar/tmp/banner/images/999-offer-banner-mobile.jpg',
    alt_text: '',
  },
]
const articlesData = [
  {
    article:
      'https://timesofindia.indiatimes.com/videos/etimes/bollywood/from-sussanne-khan-to-divya-dutta-celebs-clicked-at-an-event-in-mumbai/videoshow/104924237.cms',
    logo: 'https://media.vedistry.com/media/codilar/tmp/banner/images/toi.webp',
    heading:
      'From Sussanne Khan to Divya Dutta, celebs clicked at a Charak Pharma event in Mumbai',
  },
  {
    article:
      'https://elle.in/beauty-launches-that-have-us-in-a-chokehold-this-october/?fbclid=PAAaZvnzlF6LzsdmetoJX3HNO0NxPuO10AQo-Ay6lMXtGP7PAViBDaS0ZaxX0_aem_AYAC1EaWKpSdiMhbZ8Ap4mOV1jN0wc-ZLiWemydpmppWq5ztPpc--skngmf93ni2gqQ',
    logo: 'https://elle.in/wp-content/uploads/2021/11/logo-svg.svg',
    heading: '17 New Beauty Launches That Have Us In A Chokehold This October',
  },
  {
    article:
      'https://m.dailyhunt.in/news/india/english/bollywoodailyhunt-epaper-dh7a03a960b23e41b7ad8323641897f8b5/-newsid-dh7a03a960b23e41b7ad8323641897f8b5_72cd6483f4ec49a1ab98cb066ba8bbd4?sm=Y',
    logo: 'https://m.dailyhunt.in/assets/img/desktop/logo.svg?mode=pwa&ver=4.0.104',
    heading:
      'Charak’s brand moha: continues to inspire and empire women entrepreneurs',
  },
  {
    article:
      'https://www.marketinginasia.com/revolutionize-winter-skincare-with-mohas-new-hydrating-face-serum/',
    logo: 'https://www.marketinginasia.com/wp-content/uploads/2023/01/MIA-Favicon-3D-1.png',
    heading:
      'Revolutionize Winter Skincare with moha’s New Hydrating Face Serum',
  },
  {
    article:
      'https://boldoutline.in/navratri-makeup-trends-2023-your-guide-to-mesmerizing-looks-and-skincare-rituals.html',
    logo: 'https://boldoutline.in/wp-content/uploads/2018/03/BO-logo-145.png',
    heading:
      'Navratri Makeup Trends 2023: Your Guide To Mesmerizing Looks And Skincare Rituals',
  },
]

const BannerSlider = ({ banners }) => {
  return (
    <MainSlider
      delay={4500}
      spaceBetween={50}
      slidesPerView={1}
      fade={true}
      className="banner-image"
    >
      {banners &&
        banners.map((banner, key) => (
          <SwiperSlide key={key}>
            <Link
              href={banner.link}
              onClick={() => {
                we.event('Banner Clicked', {
                  'Banner Name': `${banner?.title}`,
                  'Banner Category': `${banner?.banner_category}`,
                  Offer: `${banner?.offer}`,
                })
              }}
            >
              <Image
                key={banner.resource_path}
                url={banner.resource_path}
                label={banner.alt_text}
                loading={'eager'}
              />
            </Link>
          </SwiperSlide>
        ))}
    </MainSlider>
  )
}
const HomePage = (props) => {
  const { banners, refcode, jsonLd, videos, canonical, review } = props
  const [{ isMobile }] = useAppContext()
  const [app, setApp] = useState(null)
  const router = useRouterContext()
  const auth = useAuthContext()
  const { status, isChecked } = useSpinwheelSession()
  const [spinwheelReady, setSpinwheelReady] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (isChecked) {
        setSpinwheelReady(status !== 'used')
      }
    }, 3000)
  }, [status, isChecked])

  console.log('sincheck', { status, spinwheelReady })
  const [hideSpinWheel] = useState(() => {
    return storage.getItem('spunthewheel_may_2') == 'true'
  })
  const [open, openModal] = useState(true)
  const [offerContent, setOfferContent] = useState(null)
  useEffect(() => {
    setApp(true)
    if (refcode) {
      setReferralCode(refcode)
    }
    const offerBool = sessionStorage.getItem('offerBool')
    !offerBool &&
      setTimeout(() => {
        graphqlWebClient
          .request(
            `
        {
          cmsBlocks(identifiers: "homepage_popup") {
          items {
              identifier
              title
              content
              }
            }
          }
        `
          )
          .then(({ cmsBlocks: { items } }) => {
            setOfferContent(items[0].content)
          })
      }, 3000)
    return () => {}
  }, [])
  const [
    {
      storeConfig: { default_title, default_description },
    },
  ] = useAppContext()

  useEffect(() => {
    sendPageView('HomePage')
  }, [])

  return (
    <>
      <div className="mttop">
        <BannerSlider
          banners={(banners || []).filter((data) =>
            data.is_mobile == isMobile ? 1 : 0
          )}
        />
      </div>

      <div className="container-width">
        <ProductListAsync
          pageSize={4}
          filter={{ bestseller: { eq: '1' } }}
          title="Best Sellers"
        />
        <ProductListAsync
          pageSize={4}
          filter={{ new_arrival: { eq: '1' } }}
          title="New Arrivals"
        />

        {/* <div className='bannermidSection'>
          <a
            href="https://www.moha.co.in/bride-brigade"
            style={{
              display: 'block',
            }}
          >
            <img
              src="https://sm.moha.co.in/media/home-page-banner.jpg"
              style={{ width: '100%' }}
              className="DesktopBannerImg"
            />
            <img
              src="https://sm.moha.co.in/media/home-page-banner-sm.jpg"
              style={{ width: '100%' }}
              className="MobileBannerImg"
            />
          </a>
        </div> */}

        <ProductListAsync
          pageSize={4}
          filter={{ offerproduct: { eq: '1' } }}
          title="Offers"
        />
        <ProductListAsync
          pageSize={4}
          filter={{ bath_and_body: { eq: '1' } }}
          title="Bath and Body"
        />
        <ProductListAsync
          pageSize={4}
          filter={{ skin_basics: { eq: '1' } }}
          title="Skin Basics"
        />
        <ProductListAsync
          pageSize={4}
          filter={{ hair_basics: { eq: '1' } }}
          title="Hair Basics"
        />
        <SpotlightSection />
      </div>
      <div className="customerNewRi customerNewRihome">
        <div className="customerNewRi-inner container-width">
          <HomePageReviewsAsync />
        </div>
      </div>
      <div className="container-width">
        <HomePageTvComponentAsync videos={videos} />
        <section className="articles-container">
          <TitleTile title="News and Articles" />
          <div className="articles">
            {articlesData.map((item) => {
              return (
                <div key={item.article} className="article">
                  <a href={item.article} target="_blank" title={item.heading}>
                    <div className="article-image">
                      <img src={item.logo} alt={'article'} loading="lazy" />
                    </div>
                    {/* <h1 className="article-heading">{item.heading}</h1> */}
                  </a>
                </div>
              )
            })}
          </div>
          <LoadMore src={`/news-and-articles`} />
        </section>
        <HomePageBlogAsync />
        {/* <OffersBanner /> */}
      </div>

      {offerContent && (
        <ModalComponent
          isOpen={open}
          close={(data) => {
            openModal(false)
            sessionStorage.setItem('offerBool', 'true')
          }}
          title={''}
        >
          <div
            className="offer-modal"
            dangerouslySetInnerHTML={{ __html: offerContent }}
          ></div>
        </ModalComponent>
      )}
    </>
  )
}
export default HomePage
function SpotlightSection() {
  return (
    <section className="spotlight">
      <div className="category-title">
        <h2>IN THE SPOTLIGHT</h2>
      </div>
      <div className="spotlight-inner">
        <div className="spotlight-data">
          <img
            src="https://sm.moha.co.in/media/codilar/tmp/banner/images/spotlightNew.png"
            alt=""
          />
        </div>
        <div className="spotlight-data">
          <img
            src="https://sm.moha.co.in/media/codilar/tmp/banner/images/spotlightMain.png"
            alt=""
          />
        </div>
        <div className="spotlight-data">
          <img src="https://sm.moha.co.in/media/spotlight3.png" alt="" />
        </div>
      </div>
    </section>
  )
}

const OffersBanner = () => {
  return (
    <div className="homepage-offers-container">
      <h1>FLAT 70% OFF</h1>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
        veritatis reprehenderit cumque facilis impedit at corporis consequatur
        reiciendis quisquam sed?
      </p>
      <button>SIGN UP</button>
    </div>
  )
}
